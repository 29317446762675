@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-dots {
  top: calc(100%);
}

@media screen and (max-width: 768px) {
  .slick-dots {
    top: calc(100%);
  }
  .card {
    width: 100%;
  }
}

.slick-dots li {
  /* color: black; */
  background-color: black;
  border: black 1px solid;
}

.ant-table-tbody>tr:hover>td {
  background-color: #FA8A67 !important;
  /* Use any Tailwind color or custom color */
}

/* Apply zebra striping */
.ant-table-tbody>tr:nth-child(odd)>td {
  background-color: #b8b8b8;
  /* Light gray color for odd rows */
}

.ant-table-tbody>tr:nth-child(even)>td {
  background-color: #ffffff;
  /* White color for even rows */
}

.ant-card-body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* Stretch items to equal height */
}